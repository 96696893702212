import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageNotFound from '../components/pageNotFound';

const NotFoundPage = ({ data }) => {
  const bgList = data.allFile.edges.map((edge) => edge.node.publicURL);
  const [bgImage, setBgImage] = useState(null);

  useEffect(() => {
    const index = Math.floor(Math.random() * bgList.length);
    const gif = bgList[index];
    setBgImage(gif);
  });

  return (
    <Layout>
      <SEO title='404: Not found' />
      <PageNotFound backgroundImage={bgImage}>
        <section>
          <h1>This is awkward</h1>
          <h2>We can't find the page you're after.</h2>
          <nav>
            <a href='/'>Get back on track</a>
          </nav>
        </section>
      </PageNotFound>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allFile(filter: { absolutePath: { regex: "//notfound//" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`;

export default NotFoundPage;
