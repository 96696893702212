import React from 'react';
import { pageNotFound } from './pageNotFound.module.scss';

const PageNotFound = ({ backgroundImage, children }) => {
  const style = { backgroundImage: `url(${backgroundImage})` };

  const classes = [pageNotFound, 'isCompact'];

  return (
    <header
      data-layout-hero
      data-use-palette
      data-module
      data-content-type='pageNotFound'
      className={classes.join(' ')}
      style={style}
    >
      {children}
    </header>
  );
};

export default PageNotFound;
